import axios from "axios";
import qs from "qs";
import { removeCometChat } from "src/lib/utils/cometchat/functions";

// auth
export const checkDbForUser = async (data) => {
  const req = axios.get("/api/auth/checkDbForUser/:id", {
    params: { email: data?.email, token: data?.token },
  });
  const res = await req;
  return res.data;
};

export const signIn = async (user) => {
  const req = axios.get("/api/auth/signIn/:id", { params: user });
  const res = await req;
  return res.data;
};

export const signOut = async () => {
  const req = axios.post("/api/auth/signOut");
  const res = await req;

  removeCometChat();

  return res.data;
};

export const addUser = async (user) => {
  const req = axios.post("/api/auth/addUser ", user);
  const res = await req;
  return res.data;
};

export const addLocation = async (zip) => {
  const req = axios.post("/api/auth/addLongAndLat", zip);
  const res = await req;
  return res.data;
};

export const checkCredentials = async (user) => {
  const req = axios.post("/api/auth/checkCredentials", user);
  const res = await req;
  return res.data;
};

export const resetPassword = async (email) => {
  const req = axios.post("/api/auth/resetPassword", { email: email });
  const res = await req;
  return res.data;
};

// Cloudinary
export const getSignature = async (folder) => {
  const req = axios.post("/api/cloudinary/getSignature", { folder: folder });
  const res = await req;
  return res.data;
};

export const uploadImageToCloudinary = async (url, formData) => {
  const req = axios.post(url, formData);
  const res = await req;
  return res.data;
};

export const deleteImages = async (imgArr) => {
  const req = axios.post("/api/cloudinary/deleteImages", imgArr);
  const res = await req;
  return res.data;
};

// general user api calls
export const updateUserAvatar = async (userId, avatarUrl) => {
  const req = axios.put("/api/user/account/avatar", {
    userId: userId,
    avatarUrl: avatarUrl,
  });
  const res = await req;
  return res.data;
};

export const updateUserDetails = async (userData) => {
  const req = axios.put("/api/user/account", { userData: userData });
  const res = await req;
  return res.data;
};

export const updateUserPassword = async (accessToken, password) => {
  const req = axios.put("/api/user/password", {
    accessToken: accessToken,
    password: password,
  });
  const res = await req;
  return res.data;
};

export const deleteAccountsAndData = async (
  userId,
  authId,
  email,
  password
) => {
  const req = axios.delete("/api/user/account", {
    userId: userId,
    authId: authId,
    email: email,
    password: password,
  });
  const res = await req;
  return res.data;
};

// Wholesaler Contracts

// FSBO Listings
export const addListing = async (data) => {
  const req = axios.post("/api/user/listings", data);
  const res = await req;
  return res.data;
};

// Chargebee-related api calls
export const generateNewCheckoutUrl = async (user) => {
  const req = axios({
    method: "post",
    url: "/api/pricing/chargebee/generate_checkout_new_url",
    data: qs.stringify(user),
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  });
  const res = await req;
  return res.data;
};

export const fetchCbSubInfo = async (user) => {
  const req = axios.get("/api/pricing/chargebee/fetchSubInfo/:id", {
    params: user,
  });
  const res = await req;
  return res.data;
};

export const generateNewPortalSession = async (userSubId) => {
  const req = axios({
    method: "post",
    url: "/api/pricing/chargebee/generate_portal_session",
    data: qs.stringify({ userSubId: userSubId }),
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  });
  const res = await req;
  return res.data;
};

export const fetchUserSubInfo = async (userId) => {
  const req = axios.get(`/api/pricing/chargebee/fetchUserSubInfo/${userId}`);
  const res = await req;
  return res.data;
};

export const useUpdatedChargebeeInfo = async (userId, chargebeeId) => {
  const req = axios.post("/api/pricing/chargebee/updateUserInfo", {
    userId: userId,
    chargebeeId: chargebeeId,
  });
  const res = await req;
  return res.data;
};

// CometChat
export const createChatUser = async (
  userId,
  firstName,
  lastName,
  avatarUrl
) => {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: `{"uid":"${userId.toString()}","name":"${firstName} ${lastName}"${
      avatarUrl ? `,"avatar":"${avatarUrl}"` : null
    }}`,
  };

  const req = axios.post("https://api-us.cometchat.io/v2.0/users", options);
  const res = await req;
  return res.data;
};

export const addContactToCometChat = async (cashBuyer, wholesaler) => {
  const req = axios.post("/api/comet/contacts/addFriends", {
    cashBuyer,
    wholesaler,
  });
  const res = await req;
  return res.data;
};

export const addContactToSupaBase = async (wholesaler, cashBuyer) => {
  const req = axios.post("/api/comet/contacts/addContact", {
    cashBuyer,
    wholesaler,
  });
  const res = await req;
  return res.data;
};

export const getUserConversations = async (userId) => {
  const options = {
    method: "GET",
    headers: {
      appId: process.env.NEXT_PUBLIC_COMETCHAT_APP_ID,
      apiKey: process.env.NEXT_PUBLIC_COMETCHAT_API_KEY,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const req = axios.get(
    `https://api-us.cometchat.io/v2.0/users/${userId.toString()}/conversations?conversationType=user&withTags=false`,
    options
  );
  const res = await req;
  return res?.data?.data;
};

export const getUnreadMessagesCount = async (userId) => {
  const options = {
    method: "GET",
    headers: {
      appId: process.env.NEXT_PUBLIC_COMETCHAT_APP_ID,
      apiKey: process.env.NEXT_PUBLIC_COMETCHAT_API_KEY,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const req = axios.get(
    `https://api-us.cometchat.io/v2.0/users/${userId.toString()}/messages?${qs.stringify(
      {
        unread: true,
        hideMessagesFromBlockedUsers: true,
        perPage: 1000,
        withTags: false,
      }
    )}`,
    options
  );
  const res = await req;
  return res?.data?.data?.length;
};

//New Chat

export const acceptFriendRequest = async (userId, senderId) => {
  const req = axios.post("/api/comet/accept/friends", {
    userId,
    senderId,
  });
  const res = await req;
  return res.data;
};

export const acceptDeal = async (id) => {
  const req = axios.post("/api/comet/accept/deals", {
    id,
  });
  const res = await req;
  return res.data;
};
export const completeDeal = async (id, wholesalerContract) => {
  const req = axios.post("/api/comet/complete/deals", {
    id,
    wholesalerContract,
  });
  const res = await req;
  return res.data;
};

export const getMessageTranscript = async (userId, friendId) => {
  const options = {
    method: "GET",
    headers: {
      appId: process.env.NEXT_PUBLIC_COMETCHAT_APP_ID,
      apiKey: process.env.NEXT_PUBLIC_COMETCHAT_API_KEY,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  const req = axios.get(
    `https://api-us.cometchat.io/v2.0/users/${userId}/users/${friendId}/messages`,
    options
  );
  const res = await req;
  return res.data;
};

export const denyFriendRequest = async (userId, senderId) => {
  const req = axios.post("/api/comet/deny/friends", {
    userId,
    senderId,
  });
  const res = await req;
  return res.data;
};

export const denyDeal = async (userId, senderId) => {
  const req = axios.post("/api/comet/deny/deals", {
    userId,
    senderId,
  });
  const res = await req;
  return res.data;
};

export const addFriendsCometChatApi = async (userId, friendId) => {
  const options = {
    method: "POST",
    headers: {
      appId: process.env.NEXT_PUBLIC_COMETCHAT_APP_ID,
      apiKey: process.env.NEXT_PUBLIC_COMETCHAT_API_KEY,
      "Content-Type": "application/json",
      Accept: "application/json",
      body: JSON.stringify({ accepted: [friendId] }),
    },
  };
  const req = axios.post(
    `https://api-us.cometchat.io/v2.0/users/${userId}/friends`,
    options
  );
  const res = await req;
  return res.data;
};

export const getUnreadMessages = async (userId) => {
  const options = {
    method: "GET",
    headers: {
      appId: process.env.NEXT_PUBLIC_COMETCHAT_APP_ID,
      apiKey: process.env.NEXT_PUBLIC_COMETCHAT_API_KEY,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  const req = axios.get(
    `https://api-us.cometchat.io/v2.0/users/${userId}/messages?count=true&perPage=100&withTags=false`,
    options
  );
  const res = await req;
  return res.data;
};

export const addFriendsToSupabaseDb = async (
  userId,
  recipientId,
  contractId,
  fsboId
) => {
  const req = axios.post("/api/comet/friends/request", {
    userId,
    recipientId,
    contractId,
    fsboId,
  });
  const res = await req;
  return res.data;
};

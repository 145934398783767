export const removeCometChat = () => {
  if (typeof window !== "undefined") {
    if (CometChatWidget) {
      // log out of CometChat
      CometChatWidget.logout();

      // remove the iframe from the document body
      document
        .getElementsByTagName("body")[0]
        .removeChild(document.getElementById("cometchat__widget"));

      // delete the initialized CometChat script from the window object
      delete window.CometChatWidget;
    }

    // remove all CometChat items from localStorage
    for (let i = 0; i < localStorage.length; i++) {
      if (
        localStorage
          .key(i)
          .includes(process.env.NEXT_PUBLIC_COMETCHAT_APP_ID) ||
        localStorage
          .key(i)
          .includes(process.env.NEXT_PUBLIC_COMETCHAT_AUTH_KEY) ||
        localStorage.key(i).includes("cometchat")
      ) {
        localStorage.removeItem(localStorage.key(i));
      }
    }
  }
};
